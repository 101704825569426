import { Navigate, createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import GuestLayout from "./layouts/GuestLayout";
import Login from "./pages/Login";
import Forbiden from "./pages/Forbiden";
import NotFound from "./pages/NotFound";
import ServerError from "./pages/ServerError";
import Users from "./pages/User/Users";
import AddUser from "./pages/User/AddUser";
import EditUser from "./pages/User/EditUser";
import Home from "./pages/Home";
import News from "./pages/news/News";
import Gallery from "./pages/gallery/Gallery";
import Personnels from "./pages/personnels/Personnels";

const router=createBrowserRouter([
    {
        path:'/',
        element:<DefaultLayout/>,
        children:[
            {
                path:'/',
                element:<Navigate to="/home"/>
            },
            {
                path:'/home',
                element:<Home/>
            },
            
            {
                path:'/403',
                element:<Forbiden/>
            },
            {
                path:'/404',
                element:<NotFound/>
            },
            
            {
                path:'/users',
                element:<Users/>
            },
            {
                path:'/user/new',
                element:<AddUser/>
            },
            {
                path:'/user/:id',
                element:<EditUser/>
            },
            {
                path:'/news',
                element:<News/>
            },
            {
                path:'/gallery',
                element:<Gallery/>
            },
            {
                path:'/staff',
                element:<Personnels/>
            },
            {
                path:'*',
                element:<NotFound/>
            },
            
        ]
    },
    {
        path:'/',
        element:<GuestLayout/>,
        children:[
            {
                path:'/login',
                element:<Login/>
            },
            {
                path:'/404',
                element:<NotFound/>
            },
            {
                path:'*',
                element:<NotFound/>
            },
            
            
        ]
    },
    {
        path:'/500',
        element:<ServerError/>
    },
    {
        path:'*',
        element:<NotFound/>
    }
    
    
])

export default router;
import React from 'react'
import { useStateContext } from '../contexts/ContextProvider';

const Input = ({ label, labelAr, type, value, onChange, required, placeholder, width, readOnly }) => {
    const { setToken, user, } = useStateContext();
    return (
        <div className='w-full'>
            {
                user.langue === 'EN' ?
                    <div className="flex">
                        <label className="block text-sm font-medium leading-6 text-gray-900">{label}{required && <span className='text-red-500'> *</span>}</label>
                    </div>
                    :
                    user.langue === 'AR' ?
                        <div className="flex justify-end items-end">
                            <label className="block mr-1 text-sm font-medium leading-6 text-gray-900">{required && <span className='text-red-500'> *</span>}{labelAr}</label>
                        </div>
                        :
                        <div className="flex justify-between">
                            <label className="block text-sm font-medium leading-6 text-gray-900">{label}{required && <span className='text-red-500'> *</span>}</label>
                            <label className="block mr-1 text-sm font-medium leading-6 text-gray-900">{required && <span className='text-red-500'> *</span>}{labelAr}</label>
                        </div>


            }

            <div className="mt-2 ml-1">
                <input type={type} value={value} onChange={onChange} required={required} readOnly={readOnly} placeholder={placeholder} className="block w-full rounded-md border-2 border-gray-500  py-1.5 px-3 text-gray-900 shadow-sm   placeholder:text-gray-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none  sm:text-sm sm:leading-6" />
            </div>
        </div>
    )
}

export default Input
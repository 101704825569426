import React from 'react'
import { Bs7Circle, BsCalendar2Range, BsFillBuildingFill } from 'react-icons/bs'
import { GrGallery } from "react-icons/gr";
import { FiShoppingCart, FiUsers } from 'react-icons/fi'
import { useStateContext } from '../../contexts/ContextProvider'
import { FaRegCalendarDays, FaRegNewspaper } from 'react-icons/fa6'
import NavComp from '../NavComp'
import { FaUsers } from 'react-icons/fa';
import { VscGitPullRequestNewChanges } from "react-icons/vsc";
const AdminRole = () => {
  const activeLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg text-white bg-blue-600 text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3  bp-3 rounded-lg  text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
  const { setActiveMenu, screenSize, currentColor } = useStateContext();


  const handleCloseSidebar = () => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    }
  }
    return (
        < >
            <div>
                <NavComp link={`/users`}   label="Users" labelAr="المستخدمين" icon={<FiUsers/>} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/staff`}   label="Staff" labelAr="الأقسام" icon={<FaUsers />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/news`}   label="News" labelAr="التسجيلات" icon={<FaRegNewspaper />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/gallery`}   label="Gallery" labelAr="الغيابات" icon={<GrGallery />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                <NavComp link={`/registrationrequests`}   label="Registrations" labelAr="الغيابات" icon={<VscGitPullRequestNewChanges />} activeLink={activeLink} normalLink={normalLink} currentColor={currentColor}/>
                
            </div>
        </>
    )
}
export default AdminRole
import React from 'react'
import Select from 'react-select';
import { useStateContext } from '../contexts/ContextProvider';

const MySelect = ({ label, labelAr, placeholder, value, getOptionLabel, getOptionValue, options, onInputChange, onChange }) => {
    const { setToken, user, } = useStateContext();
    return (
        <div className='flex-col w-full'>
            {
                user.langue === 'EN' ?
                    <div className="flex">
                        <label className="block text-sm font-medium leading-6 text-gray-900">{label} <span className='text-red-500'> *</span></label>
                    </div>
                    :
                    user.langue === 'AR' ?
                        <div className="flex justify-end items-end">
                            <label className="block text-sm font-medium leading-6 text-gray-900"> <span className='text-red-500'>* </span>{labelAr}</label>
                        </div>
                        :
                        <div className="flex justify-between">
                            <label className="block text-sm font-medium leading-6 text-gray-900">{label} <span className='text-red-500'> *</span></label>
                            <label className="block text-sm font-medium leading-6 text-gray-900"> <span className='text-red-500'>* </span>{labelAr}</label>
                        </div>
            }

            <Select className='mt-2 outline-1 ' placeholder={placeholder} cacheOptions defaultOptions value={value} getOptionLabel={getOptionLabel} getOptionValue={getOptionValue} options={options} onInputChange={onInputChange} onChange={onChange} />
        </div>
    )
}

export default MySelect
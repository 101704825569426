import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useStateContext } from '../contexts/ContextProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosClient from '../axios-client';

const Langues = () => {
    const { setToken, user,setUser } = useStateContext();
    const [langues, setLangues] = useState([
        {
            code: 'AR',
            value: 'العربية'
        },
        {
            code: 'EN',
            value: 'English'
        }
    ]);
    const [selectedLangue, setSelectedLangue] = useState();
    const [inputValueLangue, setValueLangue] = useState();

    useEffect(()=>{
        if(user.langue==='EN'){
            setSelectedLangue({
                code: 'EN',
                value: 'English'
            })
        }else{
            if(user.langue==='AR'){
                setSelectedLangue({
                    code: 'AR',
                    value: 'العربية'
                },)
            }else{
                setSelectedLangue(null);
            }
        }
    },[]);

    const navigate = useNavigate();
    const [errors, setErrors] = useState(null);

    const updateLangue = (code) => {
        axiosClient.put('/user/updateLangue/'+code)
            .then(response => {
                
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const handleChangeLangue = value => {
        setSelectedLangue(value);
        setUser({ ...user, langue: value.code });
        updateLangue(value.code);


    }

    const handleInputChangeLnague = value => {
        setValueLangue(value);
    };

    return (
        <div>
            {/* <Select className=' outline-1 ' placeholder="Langue" cacheOptions defaultOptions value={selectedLangue} getOptionLabel={e => e.value} getOptionValue={e => e.code} options={langues} onInputChange={handleInputChangeLnague} onChange={handleChangeLangue} /> */}
        </div>
    )
}

export default Langues
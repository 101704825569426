import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import { BsCalendar2Range, BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import Loading from '../../components/Loading';
import { useStateContext } from '../../contexts/ContextProvider';
import ActionButton from '../../components/ActionButton';
import { IoIosSearch } from 'react-icons/io';
import { FaEdit, FaEye, FaRegFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Alert from '../../components/Alert';
import { LuMousePointerClick } from 'react-icons/lu';
import TabHead from '../../components/TabHead';
import { FaRegNewspaper } from 'react-icons/fa6';
import AddNews from './AddNews';
import EditNews from './EditNews';

const News = () => {
    const navigate = useNavigate();
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { setToken, user } = useStateContext();
    const [showAdd, setShowAdd] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [showAddAbsence, setShowAddAbsence] = useState(false);
    const tableRef = useRef(null);
    const [req, setReq] = useState({
        exp: '',
        classe: '',
    });

    const closeAdd = () => {
        setShowAdd(false);
    }

    const closeEdit = () => {
        setShowEdit(false);
        setSelectedNews(null);
    }

      const getNews = () => {
        setLoading(true);
        axiosClient.get('/news')
          .then((data) => {
            setNews(data.data.news);
            setLoading(false);
          }).catch(err => {
            setLoading(false);
            const response = err.response;
            if (err.response.status === 401) {
              setToken(null);
            } else {
              if (response.status === 401) {
                setToken(null);
              }
              else {
                if (response.status === 403) {
                  navigate('/403');
                } else {
                  if (response.status === 404) {
                    navigate('/404');
                  }
                  else {
                    navigate('/500');
                  }
                }
              }
            }
          })
      }
    

    const supprimer = (news) => {
        Swal.fire({
            title: "Confirmation",
            text: "Are you sure to delete this element?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/news/' + news.id)
                    .then(response => {
                        toast.success('Operation completed successfully!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getNews();
                    }).catch(err => {
                        const response = err.response;
                        if (err.response.status === 401) {
                            setToken(null);
                        } else {
                            if (response.status === 401) {
                                setToken(null);
                            }
                            else {
                                if (response.status === 403) {
                                    navigate('/403');
                                } else {
                                    if (response.status === 404) {
                                        navigate('/404');
                                    }
                                    else {
                                        navigate('/500');
                                    }
                                }
                            }
                        }

                    })
            }
        });
    }




    useEffect(() => {
        //getNews();
    }, []);
    return (
        <>
            <Header icon={<FaRegNewspaper />} category='Page' title='News' titleAr={"الجداول الزمنية"} />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex-col '>
                <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between'>
                    <div className='items-end mt-4'>
                        <Button onClick={() => { setShowAdd(true) }} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="New" textColor="text-white" />
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-2">
                        <div className='mt-8'>
                            <Button onClick={() => getNews()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Search" textColor="text-white" />
                        </div>
                    </div>
                </div>

                <div className='mt-3 w-full overflow-x-auto'>
                    <table ref={tableRef} className='table-auto'>
                        <thead>
                            <tr>
                                <th><TabHead left="Date" right="القسم" /></th>
                                <th><TabHead left="Title" right="القسم" /></th>
                                <th><TabHead left="Expiration" right="القسم" /></th>
                                <th><TabHead left="User" right="المادة" /></th>
                                <th><TabHead left="Actions" right="المادة" /></th>
                            </tr>
                        </thead>
                        {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="9"><Loading /></td></tr></tbody> :
                            (

                                <tbody>
                                    {news && news.map(o => (
                                        <tr key={o.id}>
                                            <td>{o.created_at}</td>
                                            <td>{o.title}</td>
                                            <td>{o.expiration}</td>
                                            <td>{o.user.nom}</td>
                                            <td>
                                                <div className="flex justify-center w-full gap-2 items-center">
                                                    <ActionButton onClick={() => {
                                                        setSelectedNews(o);
                                                        setShowEdit(true);
                                                    }} color="bg-green-500" icon={<FaEye />} textColor="text-white" title="View" />
                                                    <ActionButton onClick={() => supprimer(o)} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Delete" />
                                                </div>
                                            </td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            )
                        }
                    </table>
                </div>
                {
                    showAdd ? <AddNews onClose={closeAdd} /> : <></>
                }
                {
                    showEdit ? <EditNews selectedNews={selectedNews} onClose={closeEdit} /> : <></>
                }
                
            </div>
        </>
    )
}

export default News
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import axiosClient from '../../axios-client';
import Input from '../../components/Input';
import { Button, Header } from '../../components';
import Alert from '../../components/Alert';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { BiSave } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import MySelect from '../../components/MySelect';
import { GiCancel } from 'react-icons/gi';

const AddUser = () => {
  
  const [items, setItems] = useState([]);
  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [errors, setErrors] = useState(null);
  const { token, setToken } = useStateContext()
  const [user, setUser] = useState({
    id: '',
    username: '',
    nom: '',
    password: '',
    password_confirmation: '',
    role: ''
  })
  const navigate = useNavigate();
  // handle input change event
  const handleInputChange = value => {
    setValue(value);
  };

  // handle selection
  const handleChange = value => {
    setSelectedValue(value);
    setUser({ ...user, role: value.id })
  }

  useEffect(() => {
    getRoles();
  }, [])
  const getRoles = () => {
    axiosClient.get('/roles')
      .then((data) => {
        setItems(data.data.roles);
      })
  }

  const onSubmit = (ev) => {
    ev.preventDefault();
    setErrors(null);
    setLoading(true);
    axiosClient.post('/user/save', user)
      .then((response) => {
        setLoading(true);
        navigate('/users')
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
            toast.error('an error has occurred!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }
  return (
    <div>
      <Header icon={<FiUsers />} category='Page' title='Creat a new user' titleAr={"إضافة مستخدم"}/>
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <form onSubmit={onSubmit} >
        <Input label="Username(Tel)" labelAr={" اسم المستخدم (رقم الهاتف)"} type="text" value={user.username} onChange={ev => setUser({ ...user, username: ev.target.value })} required="required" />
        <Input label="Name" labelAr={"الإسم الكامل"} type="text" value={user.nom} onChange={ev => setUser({ ...user, nom: ev.target.value })} required="required" />
        <Input label="Password" labelAr={"كلمة المرور"} type="password" value={user.password} onChange={ev => setUser({ ...user, password: ev.target.value })} required="required" />
        <Input label="Password confirmation" labelAr={"تحقق من كلمة المرور"} type="password" value={user.password_confirmation} onChange={ev => setUser({ ...user, password_confirmation: ev.target.value })} required="required" />
        <MySelect label={"Role"} labelAr={"الوظيفة"} placeholder="Select a role" cacheOptions defaultOptions value={selectedValue} getOptionLabel={e => e.name} getOptionValue={e => e.id} options={items} onInputChange={handleInputChange} onChange={handleChange} />
        <div className="flex justify-end items-end mt-3 gap-2">
          {loading ? <Loading /> :
            <>
            <Button color="bg-green-500" icon={<BiSave />} size="20" text="Save" textColor="text-white" />
            <Link to='/users'>
              <Button color="bg-red-500" icon={<GiCancel />} size="20" text="Close" textColor="text-white" />
            </Link>
            </>
          }
        </div>
      </form>
    </div>
  )
}

export default AddUser